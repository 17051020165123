
class AudioFade {
    /**
     * コンストラクタ
     *
     * @param  {Object} audio      - <audio>タグのオブジェクト
     * @param  {number} [maxvol=1] - 最大ボリューム
     * @param  {number} [minvol=0] - 最低ボリューム
     * @param  {number} [wait=200] - setInterval()の間隔
     * @return {void}
     */
    constructor(audio, maxvol = 1, minvol = 0, wait = 200) {
        // iOSであれば終了する
        // if (navigator.userAgent.match(/iPhone|iPad|iPod/)) {
        //     throw {
        //         cd: "E001",
        //         message: "Does not work iOS(iPhone, iPad, iPod)",
        //         ua: navigator.userAgent
        //     };
        // }

        // 引数をプロパティに詰める
        this._audio = audio;
        this._maxvol = maxvol;
        this._minvol = minvol;
        this._wait = wait;

        // 何らかの処理がすでに実行中か
        this._isrun = false;
    }

    /**
     * Getter: 実行中フラグ
     *
     * @readonly
     * @memberof AudioFade
     */
    get isRun() {
        return (this._isrun);
    }

    /**
     * Getter: 再生状態
     *
     * @readonly
     * @memberof AudioFade
     */
    get paused() {
        return (this._audio.paused);
    }

    /**
     * フェードイン
     *
     * @param  {function} [callback=null] - フェードイン完了時に実行する関数
     * @param  {number}   [sec=3] - 最大音量になるまでの秒数
     * @return {void}
     * @public
     */
    fadeIn(callback = null, sec = 3) {
        const audio = this._audio;

        //-----------------------------------
        // 再生中 or すでに実行中ならやめる
        //-----------------------------------
        if ((!audio.paused) || (this._isrun === true)) return (false);
        this._isrun = true; // 実行中フラグを立てる

        //-----------------------------------
        // 1回あたりに増やす音量を計算
        //-----------------------------------
        const step = parseFloat((this._maxvol / ((sec * 1000) / this._wait)).toFixed(3));

        //-----------------------------------
        // 最大音量までタイマーで繰り返す
        //-----------------------------------
        let timerid = setInterval(() => {
                // 停止状態であれば再生する
                if (audio.paused) {
                    audio.volume = 0;
                    audio.play();
                } else {
                    // 最大音量に到達したらタイマー停止
                    if ((audio.volume + step) >= this._maxvol) {
                        audio.volume = this._maxvol; // 最後の音量は決め打ち
                        this._isrun = false; // 実行フラグを下ろす
                        clearInterval(timerid); // タイマー解除

                        // 完了時のCallback関数を実行
                        if (callback !== null) {
                            callback();
                        }
                    } else {
                        audio.volume += step;
                    }
                }
            },
            this._wait);
    }

    /**
     * フェードアウト
     *
     * @param  {function} [callback=null] - フェードアウト完了時に実行する関数
     * @param  {number}   [sec=3] - 最低音量になるまでの秒数
     * @return {void}
     * @public
     */
    fadeOut(callback = null, sec = 3) {
        const audio = this._audio;

        //-----------------------------------
        // 停止中 or すでに実行中ならやめる
        //-----------------------------------
        if (audio.paused || (this._isrun === true)) return (false);
        this._isrun = true; // 実行中フラグを立てる

        //-----------------------------------
        // 1回あたりに減らす音量を計算
        //-----------------------------------
        const step = parseFloat(((audio.volume - this._minvol) / ((sec * 1000) / this._wait)).toFixed(3));

        //-----------------------------------
        // 最低音量までタイマーで繰り返す
        //-----------------------------------
        let timerid = setInterval(() => {
                // 最低音量に到達したらタイマー停止
                if ((audio.volume - step) <= this._minvol) {
                    audio.volume = this._minvol; // 最後の音量は決め打ち
                    audio.pause(); // 再生を停止
                    this._isrun = false; // 実行フラグを下ろす
                    clearInterval(timerid); // タイマー解除

                    // 完了時のCallback関数を実行
                    if (callback !== null) {
                        callback();
                    }
                } else {
                    audio.volume -= step;
                }
            },
            this._wait)
    }
}

export {AudioFade}